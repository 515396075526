.privacy-box {
  margin-top: -141px;
  height: 100%;
  .privacy-content {
    padding: 40px;
    line-height: 35px;
  }
  .privacy-head {
    height: 60px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #eee;
    padding: 0 20px;

    .privacy-title {
      line-height: 60px;
    }

    .privacy_CLOSE {
      width: 40px;
      height: 40px;
      display: block;
      position: absolute;
      top: 10px;
      right: 20px;
      z-index: 9999;
      opacity: .5;
    }

    .privacy_CLOSE span {
      display: block;
      position: relative;
      width: 28px;
      height: 100%;
      margin: 0 auto;
    }

    .privacy_CLOSE span:before,
    .privacy_CLOSE span:after {
      cursor: pointer;
      -moz-border-radius: 1px;
      -webkit-border-radius: 1px;
      -o-border-radius: 1px;
      border-radius: 1px;
      height: 1px;
      width: 100%;
      background: #000;
      display: block;
      content: '';
      position: absolute;
      top: 50%;
    }

    .privacy_CLOSE span:before {
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .privacy_CLOSE span:after {
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    .privacy_CLOSE:hover {
      opacity: 1;
    }
  }
}